#vat-page {
    .main-content {
        @apply flex flex-col w-full max-w-full;
        background: #ffffff;
        box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
        border-radius: 10px;

        .head {
            width: 100%;
            height: 20px;
            background: #3b326b;
            border-radius: 10px 10px 0px 0px;
        }

        .content {
            @apply flex flex-col gap-4 p-4;
            width: 100%;

            .content-head {
                @apply flex items-center justify-between;
                width: 100%;

                .title {
                    @apply flex items-center justify-center gap-4;
                    p {
                        color: #3b326b;
                        font-weight: 400;
                        font-size: 18px;
                    }
                }

                .action {
                    @apply flex justify-between items-center;
                    height: 100%;

                    button {
                        color: #fff;
                        width: 200px;
                        background-color: #3b336b;
                        border-radius: 5px;
                        transition-duration: 0.3s;
                        font-weight: 400;
                        font-size: 16px;
                        padding: .5rem 1rem;

                        &:hover {
                            background-color: #261772;
                        }
                    }

                    p {
                        color: #ff0000;
                        font-size: 18px;
                        font-weight: 400;
                    }
                }
            }
        }
    }
}
