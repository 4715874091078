#dashboard-page {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.grid-container-1fr-1fr-white {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    margin-top: -1rem;
    @media screen and (max-width: 1550px) {
        grid-template-columns: 1fr;
    }
    .item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1rem;
        border-radius: 10px;
        box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
        .content {
            width: 47.5%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 1rem;
            button {
                width: 125px;
                flex: none;
            }
            .image {
                background: #eff3f9;
                padding: 0.5rem;
                border-radius: 10px;
            }
            .text {
                font-size: 16px;
                &-title {
                    color: #3b336b;
                    p {
                        font-weight: 500;
                        font-size: 16px;
                    }
                }
                &-description {
                    color: #9993b4;
                    font-size: 16px;
                }
            }
            .graph {
                background: #3b336b;
                padding: 0.5rem 1rem;
                border-radius: 5px;
                font-size: 16px;
                font-weight: 400;
                color: #fff;
                transition: all ease-in-out .3s;
            }
        }
    }
}

.flex-container-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    .grid-container-1fr-1fr-purple {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1rem;
        width: 50%;
        @media screen and (max-width: 1550px) {
            grid-template-columns: 1fr;
        }
        .item {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            background: #d0d0e2;
            padding: 1rem;
            gap: 0.5rem;
            color: #3b336b;
            border-radius: 10px;
            box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
            .header {
                font-size: 16px;
                font-weight: 500;
                color: #3b336b;
            }
            .content {
                display: flex;
                gap: 1rem;
                .image {
                    background: #fff;
                    border-radius: 10px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    max-width: 100px;
                    max-height: 100px;
                    padding: .5rem;
                }
                .wrapper {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    color: #3b326b;
                    line-height: 20px;
                    gap: 0.5rem;
                    width: 100%;
                    .title {
                        font-size: 16px;
                        font-weight: 400;
                    }
                    .description {
                        display: flex;
                        justify-content: space-between;
                        p {
                            font-size: 16px;
                            font-weight: 400;
                        }
                    }
                }
            }
        }
        .summary {
            display: flex;
            justify-content: space-between;
            align-items: center;
            background: #3b336b;
            padding: 0.5rem 1rem;
            margin-top: 0.5rem;
            color: #fff;
            border-radius: 10px;
            p {
                font-size: 16px;
                font-weight: 500;
            }
        }
    }
    .grid-container-1fr-1fr-orange {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1rem;
        width: 50%;
        @media screen and (max-width: 1550px) {
            grid-template-columns: 1fr;
        }
        .item {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            background: #fbe3d8;
            padding: 1rem;
            gap: 0.5rem;
            color: #3b336b;
            border-radius: 10px;
            box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
            .header {
                font-size: 16px;
                font-weight: 500;
                color: #3b336b;
            }
            .content {
                display: flex;
                justify-content: space-between;
                gap: 1rem;
                .image {
                    background: #fff;
                    border-radius: 10px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    max-width: 100px;
                    max-height: 100px;
                    padding: .5rem;
                }
                .wrapper {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    color: #3b326b;
                    line-height: 20px;
                    gap: 0.5rem;
                    width: 100%;
                    .title {
                        font-size: 16px;
                        font-weight: 400;
                    }
                    .description {
                        display: flex;
                        justify-content: space-between;
                        p {
                            font-size: 16px;
                            font-weight: 400;
                        }
                    }
                }
            }
        }
        .summary {
            display: flex;
            justify-content: space-between;
            align-items: center;
            background: #fd651f;
            padding: 0.5rem 1rem;
            margin-top: 0.5rem;
            color: #fff;
            border-radius: 10px;
            p {
                font-size: 16px;
                font-weight: 500;
            }
        }
    }
}

.grid-container-1fr-1fr-table {
    display: grid;
    grid-template-columns: 1fr 1fr;
    @media screen and (max-width: 1550px) {
        grid-template-columns: 1fr;
    }
    gap: 1rem;
    .item {
        padding: 1rem;
        border-top: 20px solid #3b326b;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
        .header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            height: 40px;
            &-title {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 1rem;
                p {
                    font-size: 16px;
                    font-weight: 400;
                    color: #3b326b;
                }
            }
            &-link {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 1rem;
                a {
                    background: #3b326b;
                    padding: 0.5rem 1rem;
                    font-size: 16px;
                    font-weight: 400;
                    width: 150px;
                    color: #fff;
                    border-radius: 5px;
                }
                p {
                    font-size: 16px;
                    font-weight: 400;
                    color: #ff0000;
                }
            }
        }
    }
}

.grid-container-1fr-table {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
    .item {
        padding: 1rem;
        border-top: 20px solid #3b326b;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
        .header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            height: 40px;
            .header-title {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 1rem;
                p {
                    font-size: 16px;
                    font-weight: 400;
                    color: #3b326b;
                }
            }
            .header-link {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 1rem;
                a {
                    background: #3b326b;
                    padding: 0.5rem 1rem;
                    font-size: 16px;
                    font-weight: 400;
                    width: 150px;
                    color: #fff;
                    border-radius: 5px;
                }
                p {
                    font-size: 16px;
                    font-weight: 400;
                    color: #ff0000;
                }
            }
        }
    }
}
