#products-export-page {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    .flex-container-column {
        border-radius: 10px;
        box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
        .product-image {
            width: 10%;
            display: flex;
            justify-content: end;
            img {
                width: 128px;
                height: 128px;
            }
        }
        .product-name {
            width: 18%;
            padding-left: 1.75rem;
            p {
                color: #969a99;
            }
            span {
                font-size: 16px;
                font-weight: 400;
            }
        }
        .product-number,
        .barcode-number {
            width: 15%;
            padding-left: 2.5rem;
            p {
                color: #969a99;
            }
            span {
                font-size: 16px;
                font-weight: 400;
            }
        }
        .barcode-image {
            border: dotted 2px #969a99;
            color: #969a99;
            border-radius: 10px;
            width: 20%;
            height: 100px;
            max-width: 190px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            // gap: 0.5rem;
            margin-inline: 2.5rem;
        }
        .supplier-title {
            display: flex;
            align-items: center;
            gap: 1rem;
            p {
                font-weight: 400;
                font-size: 16px;
                color: #3b326b;
            }
        }
    }
    .flex-container-center {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        .quantity-left {
            padding-inline: 4.5rem;
            width: 400px;
            height: 128px;
            border-radius: 10px;
            box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
            display: flex;
            flex-direction: column;
            justify-content: center;
            color: #3b326b;
            p {
                font-weight: 400;
                font-size: 12px;
            }
            span {
                font-weight: 500;
                font-size: 48px;
                line-height: 60px;
            }
        }
        .quantity-export {
            padding-inline: 4.5rem;
            width: 400px;
            height: 128px;
            border-radius: 10px;
            box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 1rem;
            color: #3b326b;
            p {
                font-weight: 400;
                font-size: 12px;
            }
            input {
                border: #c4c4c4 1px solid;
                border-radius: 5px;
                padding-left: 1rem;
                padding-block: 0.25rem;
                font-size: 16px;
                font-weight: 400;
                color: #3b326b;
                margin-bottom: 0.5rem;
            }
        }
        .submit {
            width: 400px;
            height: 128px;
            border-radius: 10px;
            box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 1rem;
            background: #3b326b;
            color: #fff;
            img {
                width: 40px;
                height: 35px;
            }
            p {
                font-weight: 400;
                font-size: 36px;
                line-height: 60px;
            }
        }
    }
}

.no-footer .MuiDataGrid-footerContainer {
    display: none;
}

.swal2-container {
    z-index: 20000 !important;
}
