.table-columns {
    color: #3B336B;
}
.table-rows {
    color: #000;
}

.flex-container-column {
    border-top: 20px solid #3b326b;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .wrapper {
            display: flex;
            align-items: center;
            gap: 1rem;
            .title {
                display: flex;
                align-items: center;
                gap: 1rem;
                color: #3B336B;
                p {
                    font-size: 18px;
                    font-weight: 400;
                }
            }
            .description {
                color: #FF0000;
                p {
                    font-size: 18px;
                    font-weight: 400;
                }
            }
        }
        .filter {
            display: flex;
            align-items: center;
            gap: 1rem;
        }
        .create {
            padding: .5rem 1rem;
            background: #eff3f9;
            color: #3b326b;
            border-radius: 5px;
            font-size: 18px;
            font-weight: 400;
            transition: all ease-in-out .3s;
            &:hover {
                background: #3b326b;
                color: #fff;
            }
        }
    }
}
