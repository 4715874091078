#products-import-page {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    .flex-container-column {
        box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
        height: 100%;
        .header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            .header-title {
                display: flex;
                align-items: center;
                gap: 1rem;
                p {
                    font-weight: 400;
                    font-size: 16px;
                    color: #3b326b;
                }
            }
            a, .btn {
                display: flex;
                align-items: center;
                gap: 1rem;
                color: #eff3f9;
                background: #3b326b;
                padding: 0.5rem 1rem;
                border-radius: 5px;
                font-size: 16px;
                font-weight: 400;
                transition: all ease-in-out 0.3s;
                width: 200px;
            }
        }
        .content {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            padding: 1rem;
            gap: 2rem;
            &-left {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 0.5rem;
                color: #969a99;

                figure {
                    padding: 1rem;
                    border: 2px dotted #969a99;
                    border-radius: 10px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    gap: 1rem;
                    width: 175px;
                    min-height: 208px;

                    img {
                        height: 100%;
                        max-height: 172px;
                    }

                    .input-file {
                        cursor: pointer;
                        height: 100%;
                        width: 100%;
                        position: absolute;
                        top: 0;
                        left: 0;
                        opacity: 0;
                        
                        input {
                            cursor: pointer;
                            width: 100%;
                            height: 100%;
                        }
                    }

                    p {
                        font-weight: 400;
                        font-size: 12px;
                    }
                }
            }
            &-right {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 1rem;
                width: 87%;
                .first-row {
                    display: flex;
                    justify-content: start;
                    gap: 1rem;
                    width: 100%;
                }
                .second-row {
                    display: flex;
                    justify-content: start;
                    gap: 1rem;
                    width: 100%;
                }
                .third-row {
                    display: flex;
                    justify-content: start;
                    gap: 1rem;
                    width: 100%;
                    @media screen and (max-width: 1550px) {
                        flex-direction: column;
                        &-left, &-right {
                            width: 100% !important;
                        }
                    }
                }
                .fourth-row {
                    display: flex;
                    justify-content: start;
                    gap: 1rem;
                    width: 100%;
                    @media screen and (max-width: 1550px) {
                        flex-direction: column;
                        &-left, &-right {
                            width: 100% !important;
                        }
                    }
                }
            }
        }
    }
}

.pricing {
    @media screen and (max-width: 1550px) {
        flex-direction: column;
        &-left, &-right {
            width: 100% !important;
        }
    }
}
