#amount-page {
    .main-content {
        @apply flex justify-center items-start w-full gap-4 p-4;
        max-width: 100%;
        min-width: 1212px;

        .content-left,
        .content-right {
            @apply flex flex-col w-1/2 h-full;
            background: #ffffff;
            box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
            border-radius: 10px;

            .head {
                width: 100%;
                height: 20px;
                background: #3b326b;
                border-radius: 10px 10px 0px 0px;
            }

            .content {
                @apply flex flex-col w-full h-full p-4 gap-4;

                .content-head {
                    @apply flex items-center justify-between w-full;

                    .title {
                        @apply flex justify-center items-center gap-4;

                        p {
                            color: #3b326b;
                            font-weight: 400;
                            font-size: 18px;
                        }
                    }

                    .action {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        gap: 1rem;

                        .create {
                            padding: .5rem 1rem;
                            color: #fff;
                            width: 200px;
                            background-color: #3b336b;
                            border-radius: 5px;
                            transition-duration: 0.3s;
                            font-size: 16px;
                            font-weight: 400;

                            &:hover {
                                background-color: #261772;
                            }
                        }

                        .delete {
                            @apply flex justify-center items-center;
                            width: 50px;
                            padding: .5rem;
                            background: #3b336b;
                            border-radius: 5px;

                            &:hover {
                                background-color: #261772;
                            }
                        }

                        p {
                            color: #ff0000;
                            font-size: 16px;
                            font-weight: 400;
                        }
                    }
                }

                .table {
                    width: 100%;
                    height: calc(100% - 65px);
                }
            }
        }
    }
}
