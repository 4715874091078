#productcate-page {
    overflow: auto;

    .main-content {
        @apply flex flex-col;
        width: 100%;
        min-width: 730px;
        height: auto;
        // min-height: 740px;
        background: #ffffff;
        box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
        border-radius: 10px;

        .head {
            width: 100%;
            height: 20px;
            background: #3b326b;
            border-radius: 10px 10px 0px 0px;
        }

        .content {
            @apply flex flex-col p-4 gap-4;
            width: 100%;
            height: 100%;

            .content-head {
                @apply flex items-center justify-between;

                .title {
                    @apply flex justify-center items-center gap-4;
                    p {
                        color: #3b336b;
                        font-weight: 400;
                        font-size: 18px;
                    }
                }

                .action {
                    @apply flex justify-end gap-4 items-center;
                    width: 34rem;
                    height: 100%;

                    button {
                        color: #fff;
                        width: 200px;
                        background-color: #3b336b;
                        border-radius: 5px;
                        transition-duration: 0.3s;
                        font-weight: 400;
                        font-size: 16px;
                        padding: .5rem 1rem;

                        &:hover {
                            background-color: #261772;
                        }
                    }
                    
                    .btn-delete {
                        @apply flex justify-center items-center;
                    }

                    p {
                        color: #ff0000;
                        font-size: 18px;
                        font-weight: 400;
                    }
                }
            }
        }
    }
}
