#create-supplier-page {
    overflow: auto;
    .main-content {
        @apply flex flex-col gap-4;
        width: 100%;
        min-width: 730px;
        height: auto;
        // min-height: 740px;

        .top-content {
            @apply flex flex-col;
            background: #ffffff;
            box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
            border-radius: 20px;

            .head {
                width: 100%;
                height: 20px;
                background: #3b326b;
                border-radius: 20px 20px 0px 0px;
            }

            .content {
                @apply flex flex-col p-4 gap-4;
                width: 100%;

                .content-head {
                    @apply flex items-center justify-between;
                    width: 100%;

                    .title {
                        @apply flex gap-2;

                        p {
                            color: #3b336b;
                            font-weight: 400;
                            font-size: 18px;
                        }
                    }

                    .action {
                        @apply flex justify-between items-center gap-4;

                        button {
                            color: #fff;
                            width: 200px;
                            background-color: #3b336b;
                            border-radius: 5px;
                            transition-duration: 0.3s;
                            padding: .5rem 1rem;
                            font-size: 16px;
                            font-weight: 400;

                            &:hover {
                                background-color: #261772;
                            }
                        }

                        p {
                            color: #ff0000;
                            font-size: 18px;
                            font-weight: 400;
                        }
                    }
                }

                .input-form {
                    @apply w-full flex flex-col gap-4 p-4;

                    .form {
                        @apply flex items-center w-full gap-4;
                    }

                    .form-3 {
                        @apply flex justify-start items-center w-full gap-4;
                        .MuiFormControl-root {
                            margin: 0;
                        }

                        button {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: 300px;
                            height: 40px;
                            color: #ffffff;
                            background: #3b336b;
                            border-radius: 5px;
                        }
                    }
                }
            }
        }

        .bottom-content {
            @apply flex flex-col;
            background: #ffffff;
            box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
            border-radius: 20px;

            .head {
                width: 100%;
                height: 20px;
                background: #3b326b;
                border-radius: 20px 20px 0px 0px;
            }

            .content {
                @apply flex flex-col p-4 gap-4;
                width: 100%;

                .content-head {
                    @apply flex items-center gap-4;
                    width: 100%;

                    .title {
                        @apply flex gap-2;

                        p {
                            color: #3b336b;
                            font-weight: 400;
                            font-size: 18px;
                        }
                    }

                    .search-input {
                        @apply flex items-center gap-2;
                        height: 100%;

                        button {
                            @apply flex items-center justify-center gap-2;
                            width: 94px;
                            height: 35px;
                            background: #3b336b;
                            border-radius: 5px;
                            color: #fff;
                        }
                    }

                    .action {
                        @apply flex justify-between items-center gap-4;

                        button {
                            color: #fff;
                            width: 141px;
                            height: 35px;
                            background-color: #3b336b;
                            border-radius: 5px;
                            transition-duration: 0.3s;

                            &:hover {
                                background-color: #261772;
                            }
                        }

                        p {
                            color: #ff0000;
                            font-size: 18px;
                            font-weight: 400;
                        }
                    }
                }

                .table {
                    @apply p-4;
                }
            }
        }
    }
}
